import { Grid } from "@mui/material";
import InfiniteLoaderGrid from "./InfininteLoaderGrid/InfiniteLoaderGrid";
const Unreviewed = ({
  loadMoreItems,
  displayedMediaFiles,
  hasNoMore,
  activeTab,
  review_Media,
  handleReiewQuote,
  perPage,
  selectForBulkApproval
}) => {
  return displayedMediaFiles.length ? (
    <Grid container className="soContainer" spacing={1}>
      <InfiniteLoaderGrid
        loadMoreItems={loadMoreItems}
        items={displayedMediaFiles}
        hasNextPage={!hasNoMore}
        activeTab={activeTab}
        handleReviewMedia={review_Media}
        handleReiewQuote={handleReiewQuote}
        pageSize={perPage}
        approved={false}
        columnCount={5}
        selectForBulkApproval={selectForBulkApproval}
      />
    </Grid>
  ) : null;
};
export default Unreviewed;
