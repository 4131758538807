import React, { useEffect, useContext, useRef, useState } from "react";

import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { connect } from "redux-zero/react";
import actions from "../../../store/actions";
import { reviewMedia } from "./networkCallsSocial";
import Loader from "../Loader_social";
import BulkUploadModal from "./BulkMediaUploadModal";

import {
  AutoAwesome,
  Collections,
  RecommendRounded,
} from "@mui/icons-material";
import "./SocialOperator.css";
import ConfirmationDialog from "../../../components/UI/ConfirmationDialog";
import { AppContext } from "../../../contexts/App.Context";
import { reviewQuote } from "../../Games/networkCalls/networkCalls";
import TabPanel from "../../../components/UI/Tabs/TabPanel";
import Unreviewed from "./Unreviewed";
import Reviewed from "./Reviewed";
import ReviewedAI from "./ReviewedAI";

const perPage = 9; // Set this to your desired number of items per page
function SocialOperator(props) {
  const loggedInUserObj = props.initialState.loggedInUserObj;
  const [mediaFilesUnReviewed, setMediaFilesUnReviewed] = useState([]); //
  const [mediaFilesReviewed, setMediaFilesReviewed] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [pageNo, setPageNo] = useState(0);
  const [hasNoMore, setHasNoMore] = useState(false);
  const [samePageTrigger, setSamePageTrigger] = useState(false);

  const reviewedItem = useRef(""); // to store last reviewd item
  useEffect(() => {
    refreshData();
  }, []);

  const [newestFirst, setNewestFirst] = useState(true);
  const [onlyImages, setOnlyImages] = useState(false);
  const [onlyVideos, setOnlyVideos] = useState(false);
  const [onlyApprovedQuotes, setOnlyApprovedQuotes] = useState(false);
  const [hasOnlyQuote, setHasOnlyQuote] = useState(false);
  const [purgeConfirmation, setPurgeConfirmation] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [enableBulkApproval, setEnableBulkApproval] = useState(false);

  const bulkApprovalIds = useRef([]);
  const bulkRejectionIds = useRef([]);

  const appContext = useContext(AppContext);

  useEffect(() => {
    setMediaFilesReviewed([]);
    setMediaFilesUnReviewed([]);
  }, [
    activeTab,
    newestFirst,
    onlyImages,
    onlyVideos,
    onlyApprovedQuotes,
    hasOnlyQuote,
  ]);

  useEffect(() => {
    if (pageNo !== 0) {
      refreshData();
    }
    if (samePageTrigger) {
      refreshData();
    }
  }, [pageNo, samePageTrigger]);

  const [displayedMediaFiles, setDisplayedMediaFiles] = useState([]);

  useEffect(() => {
    setDisplayedMediaFiles(
      activeTab === 0 ? mediaFilesUnReviewed : mediaFilesReviewed
    );
    if (mediaFilesReviewed.length === 0 && mediaFilesUnReviewed.length === 0) {
      refreshData();
    }
  }, [mediaFilesUnReviewed, mediaFilesReviewed, activeTab]);

  const refreshData = () => {
    if (activeTab === 1 || activeTab === 2) {
      loadReviewedMediaData();
    } else if (activeTab === 0) {
      loadUnreviewedMediaData();
    }
  };

  async function loadReviewedMediaData() {
    pageNo === 0 && setLoading(true);
    let mimeType = null;
    if (onlyImages) mimeType = "image";
    if (onlyVideos) mimeType = "video";

    const reviewedResponse = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(
        props.initialState.event_id,
        pageNo,
        perPage,
        mimeType,
        onlyApprovedQuotes ? true : null,
        newestFirst ? "desc" : "asc",
        searchText.length > 0 ? searchText : null,
        undefined,
        activeTab === 2 ? true : null
      ),
      method: "GET",
    });
    if (
      reviewedResponse.json &&
      reviewedResponse.json.mediaFiles.length === 0 &&
      pageNo === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      setTotalCount(0);
      return;
    }
    if (
      reviewedResponse.json &&
      reviewedResponse.json.mediaFiles.length < perPage
    ) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }
    setMediaFilesReviewed(
      mergeUnique(mediaFilesReviewed, reviewedResponse.json.mediaFiles)
    );

    setTotalCount(reviewedResponse.json.totalCount);
    setLoading(false);
  }

  async function loadUnreviewedMediaData() {
    pageNo === 0 && setLoading(true);
    let mimeType = null;
    if (onlyImages) mimeType = "image";
    if (onlyVideos) mimeType = "video";
    const unreviewedResponse = await uvenuFetcher({
      url: URLS.GET_UNREVIEWED_MEDIA(
        props.initialState.event_id,
        pageNo,
        perPage,
        mimeType,
        newestFirst ? "desc" : "asc",
        searchText.length > 0 ? searchText : null,
        undefined,
        hasOnlyQuote
      ),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      method: "GET",
    });

    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length === 0 &&
      pageNo === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      setTotalCount(0);
      return;
    }
    const uniqueFiles = mergeUnique(
      mediaFilesUnReviewed,
      unreviewedResponse.json.mediaFiles
    );
    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length < perPage
    ) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }
    setMediaFilesUnReviewed(uniqueFiles);
    setTotalCount(unreviewedResponse.json.totalCount);
    setLoading(false);
  }

  const review_Media = async (
    loggedInUserObj,
    mediaId,
    review,
    username,
    description,
    showOnQuotesChecked,
    metadata,
    isStarred
  ) => {
    reviewedItem.current = { mediaId, isStarred: isStarred };
    try {
      await reviewMedia(
        loggedInUserObj,
        mediaId,
        review,
        username,
        description,
        showOnQuotesChecked,
        review && metadata?.width && metadata?.height ? metadata : undefined,
        isStarred ?? false
      );
      if (pageNo < 0) setPageNo(0);
      refreshData();
    } catch (e) {
      console.log(e);
    }
  };
  const handleReiewQuote = async (media) => {
    reviewedItem.current = { mediaId: media._id, isStarred: media.isStarred };
    await reviewQuote(media, loggedInUserObj);
    if (pageNo < 0) setPageNo(0);
    refreshData();
  };

  function mergeUnique(oldMediaFiles, newMediaFiles) {
    const uniqueNewMediaFiles = newMediaFiles.filter(
      (newMedia) =>
        !oldMediaFiles.some((oldMedia) => oldMedia._id === newMedia._id)
    );

    let finalArray = [...oldMediaFiles, ...uniqueNewMediaFiles];
    if (
      reviewedItem?.current?.isStarred === undefined ||
      reviewedItem?.current?.isStarred === null ||
      reviewedItem?.current?.isStarred === false
    ) {
      finalArray = finalArray.filter((media) => {
        return media._id !== reviewedItem?.current.mediaId;
      });
      reviewedItem.current = "";
    } else {
      finalArray = finalArray.map((media) => {
        if (media._id === reviewedItem?.current.mediaId) {
          media.isStarred = reviewedItem?.current.isStarred;
        }
        return media;
      });
    }

    finalArray = finalArray.sort(function (a, b) {
      if (newestFirst) return new Date(b.createdAt) - new Date(a.createdAt);
      else return new Date(a.createdAt) - new Date(b.createdAt);
    });

    return finalArray;
  }

  const loadMoreItems = (startIndex, stopIndex) => {
    if (hasNoMore) {
      setSamePageTrigger(true);
      return;
    }
    let newPageNo = Math.floor(startIndex / perPage);
    setPageNo(newPageNo);
  };

  const handleTabChange = (newValue) => {
    setPageNo(0);
    setActiveTab(newValue);
  };

  const onSeachClick = () => {
    setPageNo(0);
    setMediaFilesReviewed([]);
    setMediaFilesUnReviewed([]);
  };

  const purgeAllMediaFiles = async () => {
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.PURGE_ALL_MEDIA,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: {
        eventId: props?.initialState?.event_id,
      },
    });
    setPurgeConfirmation(false);
    if (response.statusCode === 204) {
      handleTabChange(!activeTab);
      appContext.triggerToast(true, {
        type: "success",
        message: "All media purged successfully.",
      });
    } else {
      console.log(response);
    }
  };
  const LoaderComponent = () => {
    return (
      <div className="soOverlay">
        <Loader />
      </div>
    );
  };

  const onRefreshClick = () => {
    if (newestFirst === true) {
      props.forceRerender();
    } else {
      setPageNo(0);
      refreshData();
    }
  };
  const onUploadBulkClick = () => setOpenUploadModal(true);
  const onPurgeAllClick = () => {
    setPurgeConfirmation(true);
  };
  const onSortClick = () => {
    setPageNo(0);
    setNewestFirst(!newestFirst);
  };
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const onBulkApproveClick = async () => {
    if (bulkApprovalIds.current.length) {
      try {
        const response = await uvenuFetcher({
          method: "PATCH",
          url: URLS.REVIEW_BULK_MEDIA,
          headers: {
            token: `${loggedInUserObj.token}`,
          },
          body: {
            sharedMediaIds: bulkApprovalIds.current,
            isApproved: true,
          },
        });
        if (response.statusCode === 204) {
          bulkApprovalIds.current = [];

          appContext.triggerToast(true, {
            type: "success",
            message: "Bulk approved successfully.",
          });
        } else {
          console.log("error: " + response.json.message);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (bulkRejectionIds.current.length) {
      try {
        const response = await uvenuFetcher({
          method: "PATCH",
          url: URLS.REVIEW_BULK_MEDIA,
          headers: {
            token: `${loggedInUserObj.token}`,
          },
          body: {
            sharedMediaIds: bulkRejectionIds.current,
            isApproved: false,
          },
        });
        if (response.statusCode === 204) {
          bulkRejectionIds.current = [];
          appContext.triggerToast(true, {
            type: "success",
            message: "Bulk approved successfully.",
          });
        } else {
          console.log("error: " + response.json.message);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setEnableBulkApproval(false);
    onRefreshClick();
  };
  const onFilterChange = (selectedId, checked) => {
    const filters = {
      images: 1,
      videos: 2,
      onlyQuotes: 3,
      approvedQuotes: 4,
    };

    setPageNo(0);
    switch (filters[selectedId]) {
      case 1:
        setOnlyVideos(false);
        setOnlyImages(checked);
        setHasOnlyQuote(false);
        break;
      case 2:
        setOnlyImages(false);
        setHasOnlyQuote(false);
        setOnlyVideos(checked);
        break;
      case 3:
        setHasOnlyQuote(checked);
        setOnlyVideos(false);
        setOnlyImages(false);
        break;
      case 4:
        setOnlyApprovedQuotes(checked);
        break;
      default:
        setOnlyVideos(false);
        setOnlyImages(false);
        setHasOnlyQuote(false);
        setOnlyApprovedQuotes(false);
        break;
    }
  };
  const toolbarProps = {
    onSearchTextChange,
    onSeachClick,
    onRefreshClick,
    onUploadBulkClick,
    onFilterChange,
    onSortClick,
    sortDirection: newestFirst,
    uploadBulk: 0,
    total: totalCount,
    enableBulkApproval,
    onBulkApproveClick,
  };

  const reviewedUnreviewedProps = {
    loadMoreItems,
    displayedMediaFiles,
    hasNoMore,
    activeTab,
    review_Media,
    handleReiewQuote,
    perPage,
  };
  const selectForBulkApproval = (mediaId, isApproved) => {
    if (isApproved) {
      if (bulkApprovalIds.current.indexOf(mediaId) === -1) {
        bulkApprovalIds.current.push(mediaId);
      } else {
        bulkApprovalIds.current = bulkApprovalIds.current.filter(
          (id) => id !== mediaId
        );
      }
      bulkRejectionIds.current = bulkRejectionIds.current.filter(
        (id) => id !== mediaId
      );
    } else {
      if (bulkRejectionIds.current.indexOf(mediaId) === -1) {
        bulkRejectionIds.current.push(mediaId);
      } else {
        bulkRejectionIds.current = bulkRejectionIds.current.filter(
          (id) => id !== mediaId
        );
      }
      bulkApprovalIds.current = bulkApprovalIds.current.filter(
        (id) => id !== mediaId
      );
    }
    const copyOfDisplayedMediaFiles = displayedMediaFiles.map((media) => {
      if (bulkApprovalIds.current.indexOf(media._id) !== -1) {
        media["selectedClass"] = "selected-approval";
      } else if (bulkRejectionIds.current.indexOf(media._id) !== -1) {
        media["selectedClass"] = "selected-rejection";
      } else {
        delete media["selectedClass"];
      }
      return media;
    });
    if (bulkApprovalIds.current.length + bulkRejectionIds.current.length > 0) {
      setEnableBulkApproval(true);
    } else {
      setEnableBulkApproval(false);
    }
    setDisplayedMediaFiles(copyOfDisplayedMediaFiles);
  };
  const tabs = [
    {
      icon: <Collections className="w-5 h-5" />,
      label: "Unreviewed",
      content: loading ? (
        <LoaderComponent />
      ) : (
        <Unreviewed
          {...reviewedUnreviewedProps}
          selectForBulkApproval={selectForBulkApproval}
        />
      ),
    },
    {
      icon: <RecommendRounded className="w-5 h-5" />,
      label: "Approved",
      content: <Reviewed {...reviewedUnreviewedProps} />,
    },
    {
      icon: <AutoAwesome className="w-5 h-5" />,
      label: "AI Approved",
      content: <ReviewedAI {...reviewedUnreviewedProps} />,
    },
  ];

  return (
    <div className="soRoot">
      <TabPanel
        tabs={tabs}
        onTabChange={handleTabChange}
        onPurgeAllClick={onPurgeAllClick}
        toolbarprops={toolbarProps}
      />

      <BulkUploadModal
        initialState={props.initialState}
        open={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false);
        }}
        uploadAfterMath={() => {
          if (newestFirst === true) {
            props.forceRerender();
          } else {
            if (pageNo < 0) setPageNo(0);
            refreshData();
          }
        }}
      />

      <ConfirmationDialog
        title="Purge Media Confirmation"
        confirmButtonText="Yes"
        message={`All unstarred media will be purged. Are you sure you want to continue?`}
        show={purgeConfirmation}
        onConfirm={async () => {
          await purgeAllMediaFiles();
        }}
        onClose={() => {
          setPurgeConfirmation(false);
        }}
      />
    </div>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(SocialOperator);
