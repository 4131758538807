import { useState, useContext, useRef } from "react";
import {
  Cancel,
  CancelRounded,
  CheckBox,
  CloseRounded,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Input,
} from "@mui/material";
import { COLORS } from "../../utils/colors";
import { AppContext } from "../../contexts/App.Context";
import {
  deletePlaybackFile,
  updatePlaybackFile,
} from "../Games/networkCalls/networkCalls";
import Loader from "../../components/Loader/Loader";
const AudioFileListItem = ({ file, onDelete, onPlay, onAudioStop }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [name, setName] = useState(file.name);
  const [selectedItem, setSelectedShow] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AppContext);
  const audioElement = useRef(null);
  const deleteShow = async () => {
    setIsLoading(true);
    await deletePlaybackFile(selectedItem._id);
    setIsLoading(false);
    setShowConfirmationDialog(false);

    appContext.triggerToast(true, {
      type: "success",
      message: "Light show deleted successfully.",
    });
    onDelete();
  };
  const handleUpdate = async () => {
    if (name === file.name) {
      setIsEditMode(false);
      return;
    }
    const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
    setIsLoading(true);
    await updatePlaybackFile(
      { fileId: selectedItem._id, name },
      loggedInUserObj
    );
    setIsLoading(false);
    setIsEditMode(false);
    appContext.triggerToast(true, {
      type: "success",
      message: "Light show updated successfully.",
    });
    onDelete();
  };
  const renderDeleteConfirmationDialog = () => {
    return showConfirmationDialog ? (
      <>
        {isLoading && (
          <div
            style={{
              position: "fixed",
              inset: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
        <Dialog
          open={showConfirmationDialog}
          onClose={() => {
            setShowConfirmationDialog(false);
          }}
        >
          <Paper style={{ backgroundColor: COLORS.popupBackground }}>
            <DialogTitle style={{ color: COLORS.textHeader }}>
              {"Delete Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: COLORS.textHeader }}>
                {`Are you sure you want to delete "${selectedItem.name}"?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteShow}>Delete</Button>
              <Button
                onClick={() => {
                  setShowConfirmationDialog(false);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </>
    ) : null;
  };
  return (
    <>
      <div
        key={file._id}
        className="audio-file-item"
        onClick={(e) => {
          const classes =
            e.target.className && typeof e.target.className === "string"
              ? e.target.className
              : "";
          if (classes.includes("list-item")) {
            setIsEditMode(false);
          }
          setSelectedShow(file);
        }}
      >
        <div style={{}} className="lightShowTitleContainer">
          <div
            className="actionButtonsDiv"
            style={{
              borderImage: `linear-gradient(to bottom, ${file.color}, ${file.color2}) 1`,
            }}
          >
            <IconButton
              className="actionIconButton"
              onClick={(e) => {
                e.stopPropagation();
                if (selectedItem._id === file._id) {
                  setIsEditMode((pre) => !pre);
                } else {
                  setSelectedShow(file);
                  setIsEditMode(true);
                }
              }}
            >
              <Tooltip title="Edit">
                <Edit />
              </Tooltip>
            </IconButton>
            <IconButton
              className="actionIconButton"
              onClick={() => {
                setShowConfirmationDialog(true);
              }}
            >
              <Tooltip title={"Delete"}>
                <Delete />
              </Tooltip>
            </IconButton>
          </div>
          {isEditMode ? (
            <div style={{ paddingLeft: "16px" }}>
              <Input
                value={name}
                sx={{
                  color: "white",
                }}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <IconButton
                title="Cancel"
                onClick={() => {
                  setName(file.name);
                  setIsEditMode(false);
                }}
              >
                <Cancel />
              </IconButton>
              <IconButton color="success" title="Save" onClick={handleUpdate}>
                <CheckBox />
              </IconButton>
            </div>
          ) : (
            <Typography
              className="lightShowTitle"
              style={{ paddingLeft: "16px", textAlign: "center" }}
            >
              {file.name}
            </Typography>
          )}
        </div>
        <div>
          <audio
            controls
            ref={audioElement}
            onPlay={onPlay}
            onAbort={onAudioStop}
            onEnded={onAudioStop}
            onPause={onAudioStop}
            crossOrigin="anonymous"
            className="uv-audio-clip-player"
          >
            <source src={file.fileUrl} type="audio/wav" />
            <source src={file.fileUrl} type="audio/mpeg" />
            <source src={file.fileUrl} type="audio/ogg" />
          </audio>
        </div>
      </div>
      {renderDeleteConfirmationDialog()}
    </>
  );
};
export default AudioFileListItem;
