import React, { useEffect, useState } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { checkMediaOrientation, getOrientation } from "./CheckOrientation";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./FixedMasonry.css";
import FlippingCard from "./FlippingCard";
import AutoSizer from "react-virtualized-auto-sizer";
import Loader from "../../../components/Loader/Loader";
import SocialOutputSettings from "../Settings";
import {
  DEFAULT_SETTINGS_FIXED_MASONRY,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import {
  getItemFromSessionStorage,
  isLandscapeCardOfMasonry,
} from "../../../utils/utility";
import { useRef } from "react";

const POLLING_INTERVAL = 6000;
const INVENTORY_STORE_LIMIT = 200;
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.fixedMasonry;
var totalPages = 1;

const AutoResizedSocialDynamicMasonry = (props) => {
  const eventId = window.location.hash.replace("#", "") || props.eventId;
  const [NumberOfCards, setNumberOfCard] = useState(0);
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      const localSettings = getItemFromSessionStorage(
        SETTINGS_LOCALSTORAGE_KEY
      );
      return localSettings || DEFAULT_SETTINGS_FIXED_MASONRY;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [page, setPage] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [firstLoadArray, setFirstLoadArray] = useState([]);
  const [currentFlipIndex, setCurrentFlipIndex] = useState(-1);
  const [flipId, setFlipId] = useState(
    firstLoadArray.length
      ? firstLoadArray[0][0]._id + firstLoadArray[0][1]._id
      : ""
  );

  useEffect(() => {
    props.onSettingsChange(settings);
    setNumberOfCard(settings.selectedLayout);
  }, [settings]);

  useEffect(() => {
    if (props.savedSettings) {
      setSettings(props.savedSettings);
    }
  }, [props.savedSettings]);

  const landscapeInventory = useRef([]);
  const portraitInventory = useRef([]);

  const checkSparePortrait = (isCardsRendered = false) => {
    let need =
      NumberOfCards === 10 ? NumberOfCards * 2 - 4 : NumberOfCards * 2 - 2;
    if (isCardsRendered) {
      const emptyPortraitCards = document.querySelectorAll(
        '[data-orientation="portrait"] [id^="placeholder-"]'
      );
      need = emptyPortraitCards.length;
    }
    return portraitInventory.current.length > need;
  };
  const checkSpareLandscape = (supply, isCardsRendered = false) => {
    let need = NumberOfCards === 10 ? 4 : 2;
    if (isCardsRendered) {
      const emptyLandscapeCards = document.querySelectorAll(
        '[data-orientation="landscape"] [id^="placeholder-"]'
      );
      need = emptyLandscapeCards.length;
    }
    return landscapeInventory.current.length > need;
  };

  const fillPlaceholdersFromInventory = () => {
    console.log("Fill placeholder");
    const copyOfFirstLoadArray = [...firstLoadArray];
    for (let side = 0; side < 2; side++) {
      for (let i = 0; i < copyOfFirstLoadArray.length; i++) {
        if (copyOfFirstLoadArray[i][side].mimeType === "placeholder") {
          if (isLandscapeCardOfMasonry(i, settings.selectedLayout)) {
            if (landscapeInventory.current.length) {
              copyOfFirstLoadArray[i][side] =
                landscapeInventory.current.shift();
            } else if (portraitInventory.current.length) {
              const isSparePortraitAvailable = checkSparePortrait(true);
              if (isSparePortraitAvailable) {
                copyOfFirstLoadArray[i][side] =
                  portraitInventory.current.shift();
              }
            }
          } else {
            if (portraitInventory.current.length) {
              copyOfFirstLoadArray[i][side] = portraitInventory.current.shift();
            } else if (landscapeInventory.current.length) {
              const isSpareLandscapeAvailable = checkSpareLandscape(true);
              if (isSpareLandscapeAvailable) {
                copyOfFirstLoadArray[i][side] =
                  landscapeInventory.current.shift();
              }
            }
          }
        }
      }
    }

    setFirstLoadArray(copyOfFirstLoadArray);
  };

  const syncUpMasonryWithLatestFiles = async (files) => {
    console.log("SyncUp");
    const copyOfFirstLoadArray = [...firstLoadArray];
    const indicesTofill = [];
    const flattenedCardItems = [];
    for (let side = 0; side < 2; side++) {
      for (let i = 0; i < copyOfFirstLoadArray.length; i++) {
        if (
          !files.some((file) => copyOfFirstLoadArray[i][side]._id === file._id)
        ) {
          indicesTofill.push({ i, side });
        }
        if (side === 1) {
          flattenedCardItems.push(copyOfFirstLoadArray[i][side]);
          copyOfFirstLoadArray[i][side] = {
            _id: `placeholder-1-${i}`,
            mimeType: "placeholder",
          };
        }
      }
    }
    indicesTofill.forEach(({ i, side }) => {
      if (flattenedCardItems.length) {
        copyOfFirstLoadArray[i][side] = flattenedCardItems.shift();
      } else {
        copyOfFirstLoadArray[i][side] = {
          _id: `placeholder-${side}-${i}`,
          mimeType: "placeholder",
        };
      }
    });
    setFirstLoadArray(copyOfFirstLoadArray);
  };

  const fillInventories = async (
    files,
    checkIsItemCurrentlyRendered = true
  ) => {
    for (const item of files) {
      try {
        if (item.metadata && item.metadata.width && item.metadata.height) {
          if (!item.orientation) {
            item.metadata["orientation"] = getOrientation(
              item.metadata.width,
              item.metadata.height
            ).orientation;
          }
        } else {
          const result = await checkImageOrientationAsync(
            item.mediaFileUrl,
            item.mimeType,
            item._id
          );
          item.metadata = result;
        }
        const { orientation, width, height } = item.metadata;
        item.orientation = orientation;
        item.width = width;
        item.height = height;
        let inventory =
          orientation === "landscape"
            ? landscapeInventory.current
            : portraitInventory.current;

        const isItemCurrentlyDisplayed = !!document.getElementById(item._id);
        const isItemAlreadyInTheInventory = inventory.some(
          (inventoryItem) => inventoryItem._id === item._id
        );
        if (!isItemAlreadyInTheInventory) {
          if (checkIsItemCurrentlyRendered) {
            !isItemCurrentlyDisplayed && inventory.push(item);
          } else {
            inventory.push(item);
          }
        }
      } catch (error) {
        console.error("Error determining image orientation:", error.message);
      }
    }
    if (
      landscapeInventory.current.length + portraitInventory.current.length >
      0
    ) {
      const emptySides = document.querySelectorAll('[id^="placeholder-"]');
      if (emptySides.length) {
        fillPlaceholdersFromInventory();
      }
    } else {
      // Update Masonry with latest files
      const emptySides = document.querySelectorAll('[id^="placeholder-"]');
      const numberOfRenderedMedia = NumberOfCards * 2 - emptySides.length;
      if (numberOfRenderedMedia !== files.length) {
        await syncUpMasonryWithLatestFiles(files);
      }
    }
  };
  const init = async () => {
    setLoading(true);
    const { mediaFiles } = await fetchContent(page);
    setLoading(false);
    if (mediaFiles.length) {
      await fillInventories(mediaFiles);
      const initialPatternArray = getFirstLoadOrientationPatternedArray();
      setFirstLoadArray(initialPatternArray);
    } else {
      setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
    }
  };

  useEffect(() => {
    if (NumberOfCards) {
      init();
    }
  }, [NumberOfCards]);
  const refillinventory = async (p) => {
    const inventorySize =
      landscapeInventory.current.length + portraitInventory.current.length;
    if (inventorySize > INVENTORY_STORE_LIMIT) {
      return;
    }
    const { mediaFiles, totalMediaCount } = await fetchContent(p);
    if (mediaFiles.length) {
      fillInventories(mediaFiles);
    } else if (totalMediaCount === 0) {
      landscapeInventory.current = [];
      portraitInventory.current = [];
      setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
    }
  };
  useEffect(() => {
    let intervalId;
    if (firstLoadArray.length) {
      intervalId = setInterval(() => {
        if (totalPages > 1) {
          setPage((prePage) => {
            if (prePage < totalPages - 1) {
              return prePage + 1;
            } else {
              return 0;
            }
          });
        } else {
          refillinventory(0);
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [firstLoadArray.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFlipIndex((pre) => {
        if (pre < NumberOfCards - 1) {
          return pre + 1;
        } else {
          return 0;
        }
      });
    }, settings.flipDelay * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [NumberOfCards, settings.flipDelay]);

  useEffect(() => {
    if (page !== null) {
      refillinventory(page);
    }
  }, [page]);

  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            console.error(error, errorEvent);
            reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };

  const fetchContent = async (pageNumber) => {
    try {
      const perPage = NumberOfCards ? NumberOfCards * 4 : 20;
      const rawResponse = await uvenuFetcher({
        url: URLS.GET_SOCIAL_SCROLL(
          eventId,
          pageNumber,
          perPage,
          undefined,
          undefined,
          "desc"
        ),
        method: "GET",
      });
      const response = rawResponse.json;
      const mediaFiles = response?.mediaFiles || [];
      const totalMediaCount = response.totalCount;
      totalPages = Math.ceil(totalMediaCount / perPage);
      return { mediaFiles, totalMediaCount };
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  const onRequestNewMeida = (cb, orientation) => {
    if (orientation === "portrait") {
      if (portraitInventory.current.length) {
        cb(portraitInventory.current.shift());
      } else if (landscapeInventory.current.length) {
        const isSpareLandscapeAvailable = checkSpareLandscape(true);
        if (isSpareLandscapeAvailable) {
          cb(landscapeInventory.current.shift());
        }
      }
    } else if (orientation === "landscape") {
      if (landscapeInventory.current.length) {
        cb(landscapeInventory.current.shift());
      } else if (portraitInventory.current.length) {
        const isSparePortraitAvailable = checkSparePortrait(true);
        if (isSparePortraitAvailable) {
          cb(portraitInventory.current.shift());
        }
      }
    }
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen || false} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const getPlaceholderCardsArray = (cardsCount) => {
    const placeholders = [];
    for (let i = 0; i < cardsCount; i++) {
      placeholders.push([
        { _id: `placeholder-0-${i}`, mimeType: "placeholder" },
        { _id: `placeholder-1-${i}`, mimeType: "placeholder" },
      ]);
    }
    return placeholders;
  };

  const getFirstLoadOrientationPatternedArray = () => {
    const arr = [];
    const initialPortraitLength = portraitInventory.current.length;
    const initialLandscapeLength = landscapeInventory.current.length;
    if (
      portraitInventory.current.length + landscapeInventory.current.length >
      0
    ) {
      for (let side = 0; side < 2; side++) {
        for (let i = 0; i < NumberOfCards; i++) {
          if (side === 0) {
            let item1 = {
              _id: `placeholder-0-${i}`,
              mimeType: "placeholder",
            };
            if (isLandscapeCardOfMasonry(i, settings.selectedLayout)) {
              if (landscapeInventory.current.length) {
                item1 = landscapeInventory.current.shift();
              } else if (portraitInventory.current.length) {
                const isSparePortraitAvailable = checkSparePortrait();
                if (isSparePortraitAvailable) {
                  item1 = portraitInventory.current.shift();
                }
              }
            } else {
              if (portraitInventory.current.length) {
                item1 = portraitInventory.current.shift();
              } else if (landscapeInventory.current.length) {
                const isSpareLandScapeAvailable = checkSpareLandscape();
                if (isSpareLandScapeAvailable) {
                  item1 = landscapeInventory.current.shift();
                }
              }
            }
            arr.push([
              item1,
              { _id: `placeholder-1-${i}`, mimeType: "placeholder" },
            ]);
          } else {
            if (arr[i][1]._id.includes("placeholder")) {
              if (isLandscapeCardOfMasonry(i, settings.selectedLayout)) {
                if (landscapeInventory.current.length) {
                  arr[i][1] = landscapeInventory.current.shift();
                } else if (portraitInventory.current.length) {
                  const isSparePortraitAvailable = checkSparePortrait(
                    initialPortraitLength
                  );
                  if (isSparePortraitAvailable) {
                    arr[i][1] = portraitInventory.current.shift();
                  }
                }
              } else {
                if (portraitInventory.current.length) {
                  arr[i][1] = portraitInventory.current.shift();
                } else if (landscapeInventory.current.length) {
                  const isSpareLandScapeAvailable = checkSpareLandscape(
                    initialLandscapeLength
                  );
                  if (isSpareLandScapeAvailable) {
                    arr[i][1] = landscapeInventory.current.shift();
                  }
                }
              }
            }
          }
        }
      }
    }

    let emptyCards = [];
    if (arr.length < NumberOfCards) {
      const diff = NumberOfCards - arr.length;
      emptyCards = getPlaceholderCardsArray(diff);
    }
    return [...arr, ...emptyCards];
  };

  useEffect(() => {
    if (
      firstLoadArray.length &&
      firstLoadArray[currentFlipIndex] &&
      firstLoadArray[currentFlipIndex]
    ) {
      setFlipId(
        firstLoadArray[currentFlipIndex][0]._id +
          firstLoadArray[currentFlipIndex][1]._id
      );
    }
  }, [currentFlipIndex, firstLoadArray]);

  const getLayoutStyles = () => {
    if (settings.selectedLayout === 4) {
      return {
        aspectRatio: 4 / 3,
        width: props.height * (4 / 3) - settings.gap * 8 * (4 / 3),
        gridTemplateColumns: "1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 7) {
      return {
        aspectRatio: 2.4,
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns: "2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 10) {
      return {
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns:
          "1.263fr 1.263fr 2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    }
  };

  const getRootContainerStyles = () => {
    return {
      height: props.height,
      width: props.width,
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };
  return (
    <div className="root-container" style={getRootContainerStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {renderSettings()}
          <div style={getLayoutStyles()} className="uv-fixed-masonry-grid">
            {firstLoadArray.length
              ? firstLoadArray.map((s, i) => {
                  return i < NumberOfCards && s && s[0] && s[1] ? (
                    <FlippingCard
                      settings={settings}
                      m1={s[0]}
                      m2={s[1]}
                      key={s[0]._id + s[1]._id}
                      flipId={flipId}
                      onRequestNewMeida={onRequestNewMeida}
                      orientation={s[0].orientation}
                      className={`card-${settings.selectedLayout}-${i}`}
                      cardOrientation={
                        isLandscapeCardOfMasonry(i, settings.selectedLayout)
                          ? "landscape"
                          : "portrait"
                      }
                    />
                  ) : null;
                })
              : null}
          </div>
        </>
      )}
    </div>
  );
};
const SocialDynamicMasonry = (props) => {
  return (
    <>
      <div style={{ position: "fixed", inset: 0 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <AutoResizedSocialDynamicMasonry
                width={width}
                height={height}
                {...props}
              ></AutoResizedSocialDynamicMasonry>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
export default SocialDynamicMasonry;
