import AddLightShow from "./AddLightShow";
import "./LightShow.css";
import { useEffect, useState, useContext, useRef } from "react";
import {
  getLightShowsForEvent,
  deleteLightShow,
  saveLightShowSettings,
} from "../Games/networkCalls/networkCalls";
import { useLocation } from "react-router-dom";
import LightShowPreview from "./LightShowPreview";
import LightShowConsole from "./LightShowConsole";
import LightShowDetails from "./LightShowDetails";
import ConnectionManager from "../../components/Socket/ConnectionManager";
import { Delete, Edit, Stop, PlayArrow, Add } from "@mui/icons-material";

import { AppContext } from "../../contexts/App.Context";
import {
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Paper,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Input,
  ToggleButton,
  Stack,
  Switch,
  styled,
  ButtonGroup,
} from "@mui/material";
import { updateLightShow } from "../Games/networkCalls/networkCalls";
import { Settings, Wallpaper } from "@mui/icons-material";
import { COLORS } from "../../utils/colors";
import UvDialog from "../../components/UI/UvDialog";
import { getLightShowSettingsForEvent } from "../Games/networkCalls/networkCalls";
import Loader from "../../components/Loader/Loader";
import AudioFileAnalyser from "./AudioFileAnalyser";
import { socket } from "../../socket";
import UvMobileDatePicker from "../../components/UI/UvMobileDatePicker";
import { isPastTime } from "../../utils/utility";
import { parseISO, toDate } from "date-fns";
const validDataUrl = require("valid-data-url");
const LightShowHome = () => {
  const [addShow, setAddShow] = useState(false);
  const [totalShows, setTotalShows] = useState(0);
  const [showList, setShowList] = useState([]);
  const [selectedShow, setSelectedShow] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [expand, setExpand] = useState(false);
  const location = useLocation();
  const appContext = useContext(AppContext);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedMediaDataUrl, setSelectedMediaDataUrl] = useState(null);
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [holdDuration, setHoldDuration] = useState(4);
  const [repeatDelay, setRepeatDelay] = useState(10);
  const [savedSettings, setSavedSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [intensity, setIntensity] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [show, setShow] = useState({});
  const [isAnimateLogo, setIsAnimateLogo] = useState(true);
  const [isShowCountDown, setIsShowCountDown] = useState(false);
  const [isTorchShow, setIsTorchShow] = useState(false);
  const [manualActiveShowId, setManualActiveShowId] = useState(0);
  const [startTime, setStartTime] = useState(new Date());

  const consoleRef = useRef(null);

  const isTorchShowRef = useRef(null);

  const addLightShow = () => {
    setAddShow(true);
  };
  const getData = async () => {
    const list = await getLightShowsForEvent(location.state.event.event._id);
    setShowList(list || []);
    setSelectedShow(list[0]);
    setTotalShows(list.length || 0);
    setExpand(false);
  };

  const deleteShow = async () => {
    await deleteLightShow(selectedShow._id);
    setShowConfirmationDialog(false);

    appContext.triggerToast(true, {
      type: "success",
      message: "Light show deleted successfully.",
    });
    getData();
  };
  const onLightShowUpdate = () => {
    appContext.triggerToast(true, {
      type: "success",
      message: "Light show updated successfully.",
    });
    getData();
  };
  useEffect(() => {
    isTorchShowRef.current = isTorchShow;
  }, [isTorchShow]);

  useEffect(() => {
    getData();
    getEventSettings(location.state.event.event._id);
  }, []);

  const updateLightShowState = async (showPayload, isStreaming, emitData) => {
    // Update Lightshow
    if (showPayload) {
      const {
        _id,
        title,
        targetFrequency,
        color,
        effect,
        color2,
        color1HoldTime,
        color2HoldTime,
        animationDuration,
      } = showPayload;
      await updateLightShow({
        showId: _id,
        title,
        isActive: true,
        targetFrequency,
        color,
        effect,
        color2,
        color1HoldTime,
        color2HoldTime,
        animationDuration,
        isStreaming: isStreaming,
        emitData,
      });
    } else {
      await updateLightShow({
        eventId: location?.state?.event?.event._id,
      });
    }
  };

  useEffect(() => {
    if (!socketData.current) {
      return;
    }
    if (isConnected) {
      socket.emit("data", socketData.current);
      updateLightShowState(show, true, socketData.current);
    } else {
      updateLightShowState(null, false, socketData.current);
    }
  }, [isConnected]);

  const handleChange = (event, newValue) => {
    setIsTorchShow(newValue);
  };

  const renderDeleteConfirmationDialog = () => {
    return showConfirmationDialog ? (
      <Dialog
        open={showConfirmationDialog}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
      >
        <Paper style={{ backgroundColor: COLORS.popupBackground }}>
          <DialogTitle style={{ color: COLORS.textHeader }}>
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: COLORS.textHeader }}>
              {`Are you sure you want to delete ${selectedShow.title}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteShow}>Delete</Button>
            <Button
              onClick={() => {
                setShowConfirmationDialog(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    ) : null;
  };
  const saveSettings = async () => {
    const mediaForAudienceScreen = selectedMediaDataUrl
      ? validDataUrl(selectedMediaDataUrl)
        ? selectedMediaDataUrl
        : undefined
      : null;
    const payload = {
      animationSpeed,
      repeatDelay,
      holdDuration,
      mediaForAudienceScreen,
      isAnimateLogo,
      eventId: location.state.event.event._id,
      startTime,
      isShowCountDown,
    };
    setIsLoading(true);
    const response = await saveLightShowSettings(payload);
    appContext.triggerToast(true, {
      type: "success",
      message: response,
    });
    getEventSettings(location.state.event.event._id);
    setIsLoading(false);
    setShowSettings(false);
  };

  useEffect(() => {
    if (savedSettings) {
      if (savedSettings.mediaForAudienceScreen) {
        setSelectedMediaDataUrl(savedSettings.mediaForAudienceScreen);
      }
      if (
        savedSettings.animationSpeed !== null &&
        savedSettings.animationSpeed !== undefined
      ) {
        setAnimationSpeed(savedSettings.animationSpeed);
      }
      if (
        savedSettings.repeatDelay !== null &&
        savedSettings.repeatDelay !== undefined
      ) {
        setRepeatDelay(savedSettings.repeatDelay);
      }
      if (
        savedSettings.holdDuration !== null &&
        savedSettings.holdDuration !== undefined
      ) {
        setHoldDuration(savedSettings.holdDuration);
      }
      if (
        savedSettings.isAnimateLogo !== null &&
        savedSettings.isAnimateLogo !== undefined
      ) {
        setIsAnimateLogo(savedSettings.isAnimateLogo);
      }
      if (
        savedSettings.startTime !== null &&
        savedSettings.startTime !== undefined
      ) {
        setStartTime(toDate(parseISO(savedSettings.startTime)));
      }
      if (
        savedSettings.isShowCountDown !== null &&
        savedSettings.isShowCountDown !== undefined
      ) {
        setIsShowCountDown(savedSettings.isShowCountDown);
      }
    }
  }, [savedSettings]);

  const getEventSettings = async (eventId) => {
    const response = await getLightShowSettingsForEvent(eventId);
    if (response) {
      setSavedSettings(response);
    } else {
      await saveSettings();
    }
  };
  const renderSettingsDialog = (media, speed, hold, delay) => {
    return (
      <UvDialog
        show={showSettings}
        onClose={() => {
          setShowSettings(false);
        }}
        onSubmit={saveSettings}
        title="Settings"
        submitButtonLable="Save Settings"
      >
        {isLoading ? <Loader /> : null}
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <Typography fontSize="0.75rem" color="rgba(0,0,0,0.6)">
            Set Start Date & Time
          </Typography>

          <UvMobileDatePicker
            id="start-date-time"
            minDateTime={Date.now()}
            closeOnSelect
            hiddenLabel
            format="MM/dd/yyyy hh:mm a"
            value={startTime || Date.now()}
            className="uv-datepicker"
            onChange={(date) => {
              setStartTime(date);
            }}
          />
          {isPastTime(startTime) ? (
            <Typography color="error">
              Start time must be in the future.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>
        <FormControlLabel
          sx={{ width: "100%", marginTop: "20px" }}
          control={
            <Checkbox
              checked={isShowCountDown}
              onChange={(e) => {
                setIsShowCountDown(e.target.checked);
              }}
              name="enableCountDown"
              color="primary"
            />
          }
          label="Enable Showtime Countdown?"
        />
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">
            Set Animation Speed
          </InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={speed}
            onChange={(e) => {
              const newValue = e.target.value;
              setAnimationSpeed(newValue);
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">Set Hold Duration</InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={hold}
            onChange={(e) => {
              const newValue = e.target.value;
              setHoldDuration(newValue);
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">Set Repeat Delay</InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={delay}
            onChange={(e) => {
              const newValue = e.target.value;
              setRepeatDelay(newValue);
            }}
          />
        </FormControl>
        <FormControlLabel
          sx={{ width: "100%", marginTop: "20px" }}
          control={
            <Checkbox
              checked={isAnimateLogo}
              onChange={(e) => {
                setIsAnimateLogo(e.target.checked);
              }}
              name="isAnimateLogo"
              color="primary"
            />
          }
          label="Animate Logo?"
        />
        <FormControl
          variant="standard"
          sx={{
            width: "100%",
            padding: "20px 0px",
            marginTop: "20px",
          }}
        >
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <div>
                <input
                  type="file"
                  id="background-image"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const newImage = reader.result;
                        setSelectedMediaDataUrl(newImage);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <label htmlFor="background-image" style={{ cursor: "pointer" }}>
                  <IconButton
                    component="span"
                    color="primary"
                    aria-label="upload"
                    size="large"
                  >
                    <Wallpaper />
                  </IconButton>
                  Upload Logo
                </label>
              </div>
            </Grid>
          </Grid>
          {media && (
            <>
              <img
                src={media}
                alt="Logo Preview"
                style={{
                  height: "100%",
                  width: "auto",
                  margin: "10px 0",
                  objectFit: "contain",
                }}
              />

              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setSelectedMediaDataUrl(null);
                }}
              >
                Remove Logo
              </Button>
            </>
          )}
        </FormControl>
      </UvDialog>
    );
  };
  const socketData = useRef(null);
  const playEffectFromClip = (intensity, threshold, show, emitData) => {
    if (isTorchShowRef.current !== null) {
      if (show) {
        if (show.isTorchShow !== undefined && show.isTorchShow !== null) {
          show.isTorchShow = isTorchShowRef.current;
        } else {
          show["isTorchShow"] = isTorchShowRef.current;
        }
      }

      if (emitData?.settings) {
        if (
          emitData?.settings.isTorchShow !== undefined &&
          emitData?.settings.isTorchShow !== null
        ) {
          emitData.settings.isTorchShow = isTorchShowRef.current;
        } else {
          emitData.settings["isTorchShow"] = isTorchShowRef.current;
        }
      }
    }

    socket.emit("data", emitData);
    socketData.current = emitData;
    setIntensity((pre) => {
      return pre !== intensity ? intensity : pre;
    });
    setThreshold((pre) => {
      return pre !== threshold ? threshold : pre;
    });
    if (show) {
      setShow((pre) => {
        // Make sure both objects have only non object values
        if (Object.values(pre).toString() !== Object.values(show).toString()) {
          return show;
        } else {
          return pre;
        }
      });
    }
  };
  const playEffect = (intensity, threshold, show, emitData) => {
    if (isTorchShowRef.current !== null) {
      if (show) {
        if (show.isTorchShow !== undefined && show.isTorchShow !== null) {
          show.isTorchShow = isTorchShowRef.current;
        } else {
          show["isTorchShow"] = isTorchShowRef.current;
        }
      }

      if (emitData?.settings) {
        if (
          emitData?.settings.isTorchShow !== undefined &&
          emitData?.settings.isTorchShow !== null
        ) {
          emitData.settings.isTorchShow = isTorchShowRef.current;
        } else {
          emitData.settings["isTorchShow"] = isTorchShowRef.current;
        }
      }
    }

    socket.emit("data", emitData);
    setIntensity((pre) => {
      return pre !== intensity ? intensity : pre;
    });
    setThreshold((pre) => {
      return pre !== threshold ? threshold : pre;
    });
    if (show) {
      setShow((pre) => {
        // Make sure both objects have only non object values
        if (Object.values(pre).toString() !== Object.values(show).toString()) {
          return show;
        } else {
          return pre;
        }
      });
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 22.5,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#24B8E7",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 18,
      height: 18,
      borderRadius: 9,
    },
    "& .MuiSwitch-track": {
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: "#24B8E7",
      boxSizing: "border-box",
    },
  }));
  return (
    <>
      <div className="light-show-home">
        <div className="console">
          <div className="show-list-container">
            <div className="header grey-card">
              <div className="actions">
                <div className="total">Effects: {totalShows}</div>
                <ButtonGroup
                  sx={{ mt: 1, mb: 1, marginRight: "20px" }}
                  disableElevation
                  variant="contained"
                  aria-label="Disabled button group"
                >
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={addLightShow}
                    className="add-light-show-button"
                    title="Add Light Show"
                  >
                    <Add />
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => setShowSettings(true)}
                    className="add-light-show-button"
                    title="Change Settings"
                  >
                    <Settings sx={{ color: "white" }} />
                  </Button>
                </ButtonGroup>

                {showList.length ? (
                  <ConnectionManager
                    eventId={location?.state?.event?.event._id}
                    onChange={(connectionStatus) => {
                      setIsConnected(connectionStatus);
                    }}
                  />
                ) : null}
                {showList.length ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginLeft: "20px" }}
                    className="switchStack"
                  >
                    <Typography>Screen</Typography>
                    <AntSwitch
                      onChange={handleChange}
                      checked={isTorchShow}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Typography>Torch</Typography>
                  </Stack>
                ) : null}
              </div>
            </div>
            <div className="list grey-card">
              {showList.map((show, i) => (
                <div
                  key={show._id}
                  className="list-item"
                  onClick={(e) => {
                    const classes =
                      e.target.className &&
                      typeof e.target.className === "string"
                        ? e.target.className
                        : "";
                    if (classes.includes("list-item")) {
                      setExpand(false);
                    }
                    setSelectedShow(show);
                  }}
                >
                  <ToggleButton
                    value="check"
                    selected={manualActiveShowId === show._id}
                    color="error"
                    onChange={() => {
                      if (manualActiveShowId === show._id) {
                        setManualActiveShowId(0); //
                      } else {
                        setManualActiveShowId(show._id);
                      }
                    }}
                    className={
                      manualActiveShowId === show._id
                        ? "play-stop-button active"
                        : "play-stop-button"
                    }
                  >
                    {manualActiveShowId === show._id ? <Stop /> : <PlayArrow />}
                  </ToggleButton>
                  <div style={{}} className="lightShowTitleContainer">
                    <div
                      className="actionButtonsDiv"
                      style={{
                        borderImage: `linear-gradient(to bottom, ${show.color}, ${show.color2}) 1`,
                      }}
                    >
                      <IconButton
                        className="actionIconButton"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedShow._id === show._id) {
                            setExpand((pre) => !pre);
                          } else {
                            setSelectedShow(show);
                            setExpand(true);
                          }
                        }}
                      >
                        <Tooltip title="Edit">
                          <Edit />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        className="actionIconButton"
                        onClick={() => {
                          setShowConfirmationDialog(true);
                        }}
                      >
                        <Tooltip title={"Delete"}>
                          <Delete />
                        </Tooltip>
                      </IconButton>
                    </div>
                    <Typography
                      className="lightShowTitle"
                      style={{ paddingLeft: "16px", textAlign: "center" }}
                    >
                      {show.title}
                    </Typography>
                  </div>
                  {expand && show._id === selectedShow._id ? (
                    <div style={{ marginTop: "12px" }}>
                      <LightShowDetails
                        show={show}
                        onUpdate={onLightShowUpdate}
                        isTorchShow={isTorchShow}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>

          <AudioFileAnalyser
            eventId={location.state.event.event._id}
            allShows={showList}
            settings={savedSettings}
            playEffect={playEffectFromClip}
            updateLightShowState={updateLightShowState}
          />
        </div>

        <div className="preview">
          <h3>Preview</h3>
          <LightShowPreview
            pulse={intensity > threshold}
            {...show}
            {...savedSettings}
            isTorchShow={isTorchShow}
          />
        </div>
      </div>
      <AddLightShow
        show={addShow}
        closeDialog={() => {
          setAddShow(false);
          getData();
        }}
      />
      {savedSettings && savedSettings?._id ? (
        <LightShowConsole
          allShows={showList}
          eventId={location.state.event.event._id}
          playEffect={playEffect}
          settings={savedSettings}
          key={savedSettings?._id}
          manualActiveShowId={manualActiveShowId}
          ref={consoleRef}
          updateLightShowState={updateLightShowState}
        />
      ) : null}

      {renderDeleteConfirmationDialog()}
      {renderSettingsDialog(
        selectedMediaDataUrl,
        animationSpeed,
        holdDuration,
        repeatDelay
      )}
    </>
  );
};
export default LightShowHome;
