import { Add, Settings } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import AddAudioFile from "./AddAudioFile";
import { getPlaybackFilesForEvent } from "../Games/networkCalls/networkCalls";
import AudioFileListItem from "./AudioFileListItem";
import Loader from "../../components/Loader/Loader";

const AudioFileUpload = ({ eventId, onAudioPlay }) => {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [playbackFiles, setPlaybackFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const addFile = () => {
    setShowAddDialog(true);
  };

  const getFiles = async () => {
    setIsLoading(true);
    const files = await getPlaybackFilesForEvent(eventId);
    setIsLoading(false);
    setPlaybackFiles(files);
  };
  useEffect(() => {
    getFiles();
  }, []);
  const handleAudioPlay = (e) => {
    const otheraudioElements = document.getElementsByClassName(
      "uv-audio-clip-player"
    );
    for (let i = 0; i < otheraudioElements.length; i++) {
      if (otheraudioElements[i] !== e.target) {
        otheraudioElements[i].pause();
        otheraudioElements[i].currentTime = 0;
      }
    }
    onAudioPlay(e);
  };
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      )}
      <div className="audio-upload">
        <div className="header grey-card">
          <div className="actions">
            <div className="total">
              Audio File Playback: {playbackFiles.length}
            </div>
            <ButtonGroup
              sx={{ mt: 1, mb: 1, marginRight: "20px" }}
              disableElevation
              variant="contained"
              aria-label="Disabled button group"
            >
              <Button
                variant="contained"
                disableElevation
                onClick={addFile}
                className="add-light-show-button"
                title="Add Light Show"
              >
                <Add />
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="list grey-card">
          {playbackFiles.map((file) => (
            <AudioFileListItem
              key={file._id}
              file={file}
              onDelete={getFiles}
              onPlay={handleAudioPlay}
            />
          ))}
        </div>
      </div>
      <AddAudioFile
        show={showAddDialog}
        closeDialog={() => {
          setShowAddDialog(false);
        }}
        onSuccess={getFiles}
      />
    </>
  );
};
export default AudioFileUpload;
