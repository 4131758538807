import { memo } from "react";
import { motion } from "framer-motion";
import "./LightShowMobileWeb.css";

const Logo = memo(
  ({
    mediaForAudienceScreen,
    animationSpeed,
    holdDuration,
    repeatDelay,
    isAnimateLogo,
  }) => {
    const getY = () => {
      if (animationSpeed && holdDuration) {
        const totalAnimationDuration = animationSpeed * 2 + holdDuration;
        const stateCount = totalAnimationDuration / animationSpeed;
        const holdPointCount = stateCount - 1;
        const y = ["100%"];
        for (let i = 0; i < holdPointCount; i++) {
          y.push("0%");
        }
        y.push("100%");
        return y;
      } else {
        return ["100%", "0%", "0%", "100%"];
      }
    };

    const getDuaration = () => {
      if (animationSpeed && holdDuration) {
        return animationSpeed * 2 + holdDuration;
      } else {
        return 10;
      }
    };
    return (
      <div className="lsMediaContainer">
        {isAnimateLogo ? (
          <motion.img
            alt="Logo"
            src={mediaForAudienceScreen}
            style={{ width: "100%", height: "auto" }}
            initial={{ y: "100%" }}
            animate={{
              y: getY() || ["100%", "0%", "0%", "100%"],
            }}
            transition={{
              duration: getDuaration(),
              repeat: Infinity,
              repeatDelay: repeatDelay || 10,
            }}
          />
        ) : (
          <img
            alt="Logo"
            src={mediaForAudienceScreen}
            style={{ width: "100%", height: "auto" }}
          />
        )}
      </div>
    );
  }
);
export default Logo;
