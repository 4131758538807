import { useState, useEffect } from "react";
import UvDialog from "../../components/UI/UvDialog";
import { TextField, FormControl, IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Clear } from "@mui/icons-material";
import { uploader } from "../../utils/uploader";
import { URLS } from "../../constants";
import Loader from "../../components/Loader/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddAudioFile = ({ show, closeDialog, onSuccess }) => {
  const [open, setOpen] = useState(show);
  const [title, setTitle] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const reset = () => {
    setTitle("");
    setValidationMessage("");
    setUploadedFile(null);
  };
  useEffect(() => {
    reset();
    setOpen(show);
  }, [show]);

  const handleSubmit = async () => {
    if (!title) {
      setValidationMessage("Please enter a title");
      return;
    }
    if (!uploadedFile) {
      setValidationMessage("Please upload a file");
      return;
    }
    setValidationMessage("");
    const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
    setOpen(false);
    setIsLoading(true);
    const uploadResponse = await uploader({
      url: URLS.UPLOAD_AUDIO,
      body: {
        eventId: location.state.event.event._id,
        name: title,
        file: uploadedFile,
      },
      headers: { token: loggedInUserObj.token },
    });
    setIsLoading(false);
    onSuccess();
    closeDialog();
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Uploading...
          <Loader />
        </div>
      )}
      <UvDialog
        show={open}
        onClose={closeDialog}
        onSubmit={handleSubmit}
        title="Upload Audio Playback"
        submitButtonLable="Upload"
      >
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ paddingY: 1, marginTop: 1 }}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            color="primary"
            sx={{ color: "white" }}
            startIcon={<CloudUploadIcon />}
          >
            Upload an audio file
            <VisuallyHiddenInput
              type="file"
              accept="audio/*"
              onChange={(event) => {
                setUploadedFile(event.target.files[0]);
              }}
            />
          </Button>
        </FormControl>

        {uploadedFile ? (
          <div
            fullWidth
            variant="outlined"
            style={{
              padding: "20px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <audio controls>
              <source
                src={URL.createObjectURL(uploadedFile)}
                type="audio/mpeg"
              />
              <source
                src={URL.createObjectURL(uploadedFile)}
                type="audio/ogg"
              />
              Your browser does not support the audio element.
            </audio>
            <IconButton
              title="Remove this file"
              onClick={() => {
                setUploadedFile(null);
              }}
            >
              <Clear />
            </IconButton>
          </div>
        ) : null}

        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={title}
          onChange={onTitleChange}
          margin="normal"
        />
        {validationMessage ? (
          <Typography sx={{ textAlign: "center" }} variant="subtitle2">
            {validationMessage}
          </Typography>
        ) : null}
      </UvDialog>
    </>
  );
};
export default AddAudioFile;
