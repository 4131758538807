import { useEffect, useRef, useState } from "react";

const Countdown = ({ startTime }) => {
  const intervalId = useRef(null);
  const [isComplete, setIsComplete] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    if (!startTime) {
      return;
    }
    // Set a fixed target date and time (e.g., February 11, 2025, at 4:35 PM)
    const targetDate = new Date(startTime); // (Year, Month (0-11), Day, Hour, Minute, Second, Millisecond)

    function updateCountdown() {
      // Get the current time
      const now = new Date();

      // Calculate the remaining time
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        // Countdown is complete, stop the interval and hide text
        clearInterval(intervalId); // Stop the countdown timer
        setIsComplete(true);
        setTimeRemaining("");
      } else {
        // Get hours, minutes, and seconds from the remaining time
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        // Display the remaining time in the format HH:MM:SS
        setTimeRemaining(
          `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      }
    }

    // Start the countdown and update it every second
    intervalId.current = setInterval(updateCountdown, 1000);
    return () => {
      setTimeRemaining("");
      clearInterval(intervalId.current);
    };
  }, [startTime]);
  return isComplete ? null : timeRemaining ? (
    <div className="countdown-container">
      <div className="countdown-label">SHOWTIME:</div>
      <div className="countdown">{timeRemaining}</div>
    </div>
  ) : null;
};
export default Countdown;
