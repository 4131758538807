import {
  TextareaAutosize,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import "./AiTools.css";
import { useEffect, useState } from "react";
import { fal } from "@fal-ai/client";
import { NEGATIVE_PROMPT, FAL_KEY, FAL_MODEL, URLS } from "../../../constants";
import {
  DeleteRounded,
  PhotoFilterRounded,
  SaveAltRounded,
} from "@mui/icons-material";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
const AiTools = ({ media, editedImage, onGenerated, eventDetails }) => {
  const [promptList, setpromptList] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [generatedMedia, setGeneratedMedia] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [source, setSource] = useState(null);
  const [hasNsfw, setHasNsfw] = useState(false);

  useEffect(() => {
    getPrompts();
  }, [eventDetails.event_id]);

  useEffect(() => {
    if (editedImage) {
      setSource(editedImage);
    } else if (media?.mediaFileUrl) {
      setSource(media?.mediaFileUrl);
    }
  }, [media, editedImage]);

  useEffect(() => {
    if (generatedMedia) {
      setGenerating(false);
    }
  }, [generatedMedia]);

  const getPrompts = async () => {
    try {
      const response = await uvenuFetcher({
        method: "GET",
        url: URLS.GET_PROMPTS(eventDetails.event_id),
        headers: {
          token: `${eventDetails.loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        const prompts = response?.json?.prompts;
        if (prompts && prompts.length) {
          setpromptList(response.json.prompts);
          setPrompt(response.json.prompts[0].prompt);
          setSelectedPrompt(response.json.prompts[0].prompt);
        }
      } else {
        console.log(response.json.message);
        return "error: " + response.json.message;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectPrompt = (event) => {
    setSelectedPrompt(event.target.value);
    setPrompt(event.target.value);
  };

  const handleSavePromptClick = async () => {
    try {
      const response = await uvenuFetcher({
        method: "POST",
        url: URLS.SAVE_PROMPT,
        body: {
          eventId: eventDetails.event_id,
          prompt,
        },
        headers: {
          token: `${eventDetails.loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        getPrompts();
      } else {
        console.log(response.json.message);
        return "error: " + response.json.message;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePrompt = async (id) => {
    try {
      const response = await uvenuFetcher({
        method: "DELETE",
        url: URLS.DELETE_PROMPT(id),
        headers: {
          token: `${eventDetails.loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        getPrompts();
      } else {
        console.log(response.json.message);
        return "error: " + response.json.message;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateClick = async () => {
    if (!prompt) {
      alert("Please enter a prompt");
      return;
    }
    if (!source) {
      alert("Please upload a media file");
      return;
    }
    fal.config({
      credentials: FAL_KEY,
    });
    setGeneratedMedia("");
    setGenerating(true);
    try {
      const result = await fal.subscribe(FAL_MODEL, {
        input: {
          prompt,
          reference_image_url: source,
          image_size: {
            width: 256,
            height: 256,
          },
          negative_prompt: NEGATIVE_PROMPT,
        },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs.map((log) => log.message).forEach(console.log);
          }
        },
      });

      if (result && result.data) {
        setGeneratedMedia(result.data.images[0].url);
        onGenerated(result.data.images[0]);
        if (
          result.data.has_nsfw_concepts &&
          result.data.has_nsfw_concepts.indexOf(true) !== -1
        ) {
          setHasNsfw(true);
        } else {
          setHasNsfw(false);
        }
      }
    } catch (error) {
      alert(error);
      setGenerating(false);
    }
  };

  return (
    <div className="ai-tools-container">
      <div className="ait-media-container">
        <div className="source-media ait-media-box">
          <Typography className="media-label">Before</Typography>
          <div className="ait-media">
            <img
              src={source}
              alt="source-media"
              style={{ width: "100%", maxWidth: "100%" }}
            />
          </div>
        </div>
        <div className="generated-media ait-media-box">
          <Typography className="media-label">After</Typography>
          <div className="ait-media">
            {generating ? <CircularProgress /> : null}

            {generatedMedia ? (
              <img
                src={generatedMedia}
                alt="generted"
                style={{ width: "100%", maxWidth: "100%" }}
              />
            ) : null}
            {hasNsfw && <p>NSFW Content</p>}
          </div>
        </div>
      </div>

      <TextareaAutosize
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        className="ait-prompt"
        placeholder="Enter Prompt"
        minRows={3}
        style={{
          backgroundColor: "transparent",
        }}
      ></TextareaAutosize>
      <div className="generate-container">
        <Button
          disabled={generating}
          variant="contained"
          sx={{ color: "white" }}
          startIcon={<PhotoFilterRounded />}
          onClick={handleGenerateClick}
        >
          Generate
        </Button>
        <Button
          disabled={
            generating || !prompt || promptList.some((p) => p.prompt === prompt)
          }
          variant="contained"
          sx={{ color: "white", marginLeft: "16px" }}
          startIcon={<SaveAltRounded />}
          onClick={handleSavePromptClick}
        >
          Save Prompt
        </Button>
      </div>
      {promptList.length > 0 && (
        <div className="ait-saved-prompts">
          <FormControl sx={{ my: 2, mx: 1, width: "100%" }}>
            <Select
              labelId="saved-prompts-label"
              className="saved-prompts-select"
              id="saved-prompts"
              value={selectedPrompt}
              variant="outlined"
              onChange={handleSelectPrompt}
              inputProps={{ style: { border: "none" } }}
              notched={false}
              input={
                <OutlinedInput
                  value={selectedPrompt}
                  placeholder="Saved Prompts"
                  style={{ border: "none" }}
                />
              }
              style={{
                border: "none",
                color: "#fff",
                backgroundColor: "rgb(13, 14, 19)",
              }}
            >
              {promptList.map(({ prompt, _id }) => (
                <MenuItem key={_id} value={prompt}>
                  <ListItemText primary={prompt} />
                  <DeleteRounded
                    className="deleteIconInMenuItem"
                    onClick={() => {
                      handleDeletePrompt(_id);
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};
export default AiTools;
