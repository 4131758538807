import { socket } from "../../socket";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
const ConnectionManager = ({ onChange, eventId }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isConnecting, setIsConnecting] = useState(false);
  const connect = () => {
    setIsConnecting(true);
    socket.connect();
  };

  const disconnect = () => {
    socket.disconnect();
  };
  const onConnect = () => {
    if (eventId) {
      socket.emit("joinRoom", eventId);
    }
    setIsConnected(true);
    setIsConnecting(false);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const logConnectionErros = (err) => {
    // the reason of the error, for example "xhr poll error"
    console.log(err.message);
    // some additional description, for example the status code of the initial HTTP response
    console.log(err.description);
    // some additional context, for example the XMLHttpRequest object
    console.log(err.context);
  };
  const onBeforeUnload = () => {
    socket.emit("data", {
      triggerDisconnect: true,
    });
  };

  useEffect(() => {
    socket.on("connect_error", logConnectionErros);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      socket.emit("data", {
        triggerDisconnect: true,
      });
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", logConnectionErros);
      window.removeEventListener("beforeunload", onBeforeUnload);
      disconnect();
    };
  }, []);
  useEffect(() => {
    onChange(isConnected);
  }, [isConnected]);
  const handleChange = (event) => {
    let timeoutId;

    const value = event.target.checked;
    if (value) {
      connect();
    } else {
      socket.emit("data", {
        triggerDisconnect: true,
      });
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        disconnect();
      }, 100);
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 22.5,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "rgba(0, 128, 0, 0.7)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 18,
      height: 18,
      borderRadius: 9,
    },
    "& .MuiSwitch-track": {
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: "#323a4a",
      boxSizing: "border-box",
    },
  }));
  return isConnecting ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      Connecting.. <CircularProgress size={20} />
    </div>
  ) : (
    <Stack direction="row" spacing={1} className="switchStack">
      <Typography>Stop</Typography>
      <AntSwitch
        onChange={handleChange}
        checked={isConnected}
        inputProps={{ "aria-label": "ant design" }}
      />
      <Typography>Stream</Typography>
    </Stack>
  );
};
export default ConnectionManager;
