import React, { useRef, useState, useEffect } from "react";
import AudioFileUpload from "./AudioFileUpload";
import { detectionThreshold } from "./Common";
import { getRandomColor } from "../../utils/utility";
function AudioFileAnalyser({
  eventId,
  allShows,
  settings,
  playEffect,
  updateLightShowState,
}) {
  const HIGH_PULSE_THRESHOLD = 20;
  const LOW_PULSE_THRESHOLD = 2;

  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [audioSource, setAudioSource] = useState(null);

  const intervaIds = useRef([]);
  const randomColorSetMilliSeconds = useRef(null);
  const currentShowsState = useRef({});
  const lastPlayingAudioElement = useRef(null);

  useEffect(() => {
    const context = new AudioContext();
    const analyserNode = context.createAnalyser();
    analyserNode.fftSize = 8192;
    setAudioContext(context);
    setAnalyser(analyserNode);

    return () => {
      context.close();
    };
  }, []);

  const connectAudioSource = () => {
    if (audioContext && analyser && audioSource) {
      try {
        if (audioSource._sourceNode) {
          audioSource._sourceNode.connect(analyser);
          analyser.connect(audioContext.destination);
        } else {
          const source = audioContext.createMediaElementSource(audioSource);
          source.connect(analyser);
          analyser.connect(audioContext.destination);
          audioSource._sourceNode = source;
        }
      } catch (error) {
        console.error("Error connecting audio source:", error);
      }
    }
  };

  const play = (intensity, threshold, show, emitData) => {
    playEffect(intensity, threshold, show, emitData);
  };
  const stopEffect = (intensity, threshold, show, emitData) => {
    playEffect(intensity, threshold, show, emitData);
    updateLightShowState(null, false, emitData);
  };
  const checkFrequency = (targetFrequency, dataArray) => {
    analyser.getByteFrequencyData(dataArray);
    let intensity;
    let threshold;
    // Tune frequncy case
    threshold = detectionThreshold;
    const binIndex = Math.round(
      targetFrequency / (audioContext.sampleRate / analyser.fftSize)
    );
    intensity = dataArray[binIndex];

    const show = allShows.find((e) => e.targetFrequency === targetFrequency);
    if (show.effect === 4) {
      // Random color effect
      if (
        randomColorSetMilliSeconds.current === null ||
        Number(Date.now()) - Number(randomColorSetMilliSeconds.current) >
          show.animationDuration * 1000
      ) {
        randomColorSetMilliSeconds.current = Date.now();
        show.color = getRandomColor();
      }
    }

    if (intensity > threshold) {
      if (!currentShowsState.current[show._id]?.isPlaying) {
        if (currentShowsState.current[show._id]?.highPulseCount) {
          if (
            currentShowsState?.current[show._id].highPulseCount.length >
            HIGH_PULSE_THRESHOLD
          ) {
            // START SHOW
            const emitData = {
              dataArray: intensity,
              settings: { ...show, ...settings },
              eventId,
            };
            play(intensity, threshold, show, emitData);

            currentShowsState.current = {
              [show._id]: {
                isPlaying: true,
                lowPulseCount: [],
                highPulseCount: [],
              },
            };
          } else {
            currentShowsState.current[show._id].highPulseCount.push(
              show.targetFrequency
            );
          }
        } else {
          if (currentShowsState.current[show._id]) {
            currentShowsState.current[show._id].highPulseCount = [
              show.targetFrequency,
            ];
          } else {
            currentShowsState.current[show._id] = {
              isPlaying: false,
              lowPulseCount: [],
              highPulseCount: [],
            };
          }
        }
      }
    } else {
      if (currentShowsState?.current[show._id]) {
        if (
          currentShowsState?.current[show._id].lowPulseCount.length >
          LOW_PULSE_THRESHOLD
        ) {
          // STOP SHOW
          const emitData = {
            dataArray: 0,
            settings: { ...show, ...settings },
            eventId,
          };
          stopEffect(0, threshold, show, emitData);
          currentShowsState.current = {};
        } else {
          currentShowsState.current[show._id].lowPulseCount.push(intensity);
        }
      }
    }
  };

  const audioToAnimation = () => {
    if (!analyser) return;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    allShows.forEach((show) => {
      const intervalMilliSeconds = show.targetFrequency === 0 ? 25 : 10;

      if (settings !== null) {
        const checkInterval = setInterval(
          checkFrequency,
          intervalMilliSeconds,
          show.targetFrequency,
          dataArray
        );
        intervaIds.current.push(checkInterval);
      }
    });
  };
  useEffect(() => {
    const source = audioSource || lastPlayingAudioElement.current;
    if (!source) return;
    if (!allShows.length) return;

    if (audioContext?.state === "suspended") {
      audioContext.resume();
    }
    source.play();
    connectAudioSource();
    audioToAnimation();
  }, [audioSource, allShows]);

  return (
    <>
      <AudioFileUpload
        eventId={eventId}
        onAudioPlay={(e) => {
          lastPlayingAudioElement.current = e.target;
          setAudioSource(e.target);
        }}
        onAudioStop={(e) => {
          if (lastPlayingAudioElement.current === e.target) {
            lastPlayingAudioElement.current = null;
          }
          setAudioSource(null);
        }}
      />
      {/* {audioError} */}
    </>
  );
}

export default AudioFileAnalyser;
