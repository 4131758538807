import React, { useState } from "react";
import "./ToolBar.css";
import {
  Refresh,
  Search,
  Sort,
  Upload,
  KeyboardArrowDown,
  ArrowUpward,
  ArrowDownward,
  PlaylistAddCheck,
  Tune,
} from "@mui/icons-material";
function ToolBar({
  onSearchTextChange,
  onSeachClick,
  onRefreshClick,
  onUploadBulkClick,
  onFilterChange,
  onSortClick,
  sortDirection,
  uploadBulk,
  activeTab,
  total,
  enableBulkApproval,
  onBulkApproveClick
}) {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const unreviewedFilters = [
    { id: "onlyQuotes", label: "Only Quotes", checked: false },
  ];
  const reviewedFilters = [
    { id: "approvedQuotes", label: "Approved Quotes", checked: false },
  ];
  const commonFilters = [
    { id: "images", label: "Images", checked: false },
    { id: "videos", label: "Videos", checked: false },
  ];
  const filters = [...commonFilters];
  if (activeTab === 0) {
    filters.push(...unreviewedFilters);
  } else {
    filters.push(...reviewedFilters);
  }

  const handleFilterChange = (event) => {
    const checked = event.target.checked;
    const selectedId = event.target.id;
    const checkboxes = document.getElementsByClassName("tb-filter-item");
    if (checkboxes.length && selectedId !== "approvedQuotes") {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].id === selectedId) {
          checkboxes[i].checked = checked;
        } else if (checkboxes[i].id !== "approvedQuotes") {
          checkboxes[i].checked = false;
        }
      }
    }

    onFilterChange(selectedId, checked);
  };

  return (
    <div className="toolbar">
      <div className="search-container">
        <Search className="search-icon" />
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
          onChange={onSearchTextChange}
        />
        <button
          className="toolbar-button"
          style={{ marginLeft: "0.5rem" }}
          onClick={onSeachClick}
        >
          <span>Search</span>
        </button>
      </div>

      <div className="toolbar-actions">
        <span className="total">
          <b>Total: {total}</b>
        </span>
        <button className="toolbar-button" onClick={onSortClick}>
          {sortDirection ? (
            <ArrowUpward
              className="button-icon"
              style={{ marginRight: "-0.75rem" }}
            />
          ) : (
            <ArrowDownward
              className="button-icon"
              style={{ marginRight: "-0.75rem" }}
            />
          )}
          <Sort className="button-icon" />
          <span>{sortDirection ? "Newest First" : "Oldest First"}</span>
        </button>

        <div className="dropdown">
          <button
            className="toolbar-button"
            onClick={() => setShowFilterDropdown(!showFilterDropdown)}
          >
            <Tune className="button-icon" />
            <span>Filter</span>
            <KeyboardArrowDown
              className={`chevron-icon ${showFilterDropdown ? "rotate" : ""}`}
            />
          </button>

          {showFilterDropdown && (
            <div className="dropdown-content">
              {filters.map((filter) => (
                <label key={filter.id} className="dropdown-item">
                  <input
                    type="checkbox"
                    id={filter.id}
                    defaultChecked={filter.checked}
                    className="checkbox tb-filter-item"
                    onChange={handleFilterChange}
                  />
                  <span>{filter.label}</span>
                </label>
              ))}
            </div>
          )}
        </div>

        <button className="toolbar-button" onClick={onRefreshClick}>
          <Refresh className="button-icon" />
          <span>Refresh</span>
        </button>
        {uploadBulk === activeTab && (
          <button className="toolbar-button" onClick={onUploadBulkClick}>
            <Upload className="button-icon" />
            <span>Bulk Upload</span>
          </button>
        )}
        {uploadBulk === activeTab && enableBulkApproval && (
          <button className="toolbar-button" onClick={onBulkApproveClick}>
            <PlaylistAddCheck className="button-icon" />
            <span>Bulk Approve</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default ToolBar;
